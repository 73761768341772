/**
 * 
 * All JavaScript is written by Nathaniel A Mayers 2024. All rights reserved.
 * Do Not use without permission beforehand.
 * 
 * Script Version: 1.1.9
 */
/* jshint esversion: 6 */
// imports
import utils from './utils.js';
import product_manager from './products-manager.js';
import '../scss/main.scss'; // Import SCSS file
//import './command-system.js';

// Initialize the carousel
function initCarousel(container, images) {
    "use strict";
    if (!container || images.length <= 0) {
        return;
    }

    // Clone the first and last images for seamless looping
    const firstClone = images[0].cloneNode(true);
    const lastClone = images[images.length - 1].cloneNode(true);

    // Append lastClone before the first image
    container.insertBefore(lastClone, container.firstChild);
    container.appendChild(firstClone);

    const totalImages = images.length + 2; // Original images + two clones
    container.style.width = `${totalImages * 100}%`;

    let currentIndex = 1;
    container.style.transform = `translateX(-${images[0].clientWidth}px)`; // Start at the first image

    const scrollImages = function () {
        const scrollAmount = images[0].clientWidth;
        container.style.transition = 'transform 0.5s ease-in-out';
        container.style.transform = `translateX(-${scrollAmount * (currentIndex + 1)}px)`;

        utils.addEventListenerCompat(container, 'transitionend', function handleTransitionEnd() {
            if (currentIndex === images.length) {
                container.style.transition = 'none';
                container.style.transform = `translateX(-${scrollAmount}px)`; // Reset to the first image
                currentIndex = 1;
            } else if (currentIndex === 0) {
                container.style.transition = 'none';
                container.style.transform = `translateX(-${scrollAmount * images.length}px)`; // Go to the last image
                currentIndex = images.length;
            } else {
                currentIndex++;
            }
            utils.removeEventListenerCompat(container, 'transitionend', handleTransitionEnd);
        });
    };

    // Start scrolling forward
    setInterval(scrollImages, 3000);
}

// Disable & Enable scroll for breakpoints
function disableScroll() {
    "use strict";
    const scrollPosition = window.scrollY;
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollPosition}px`;
    document.body.style.width = '100%';
}

function enableScroll() {
    "use strict";
    const scrollPosition = -parseInt(document.body.style.top || '0', 10);
    document.body.style.overflow = '';
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width = '';
    window.scrollTo(0, scrollPosition);
}

// Open and close navbars
function toggleVerticalNavbar(verticalNavbars) {
    "use strict";
    verticalNavbars.forEach(verticalNavbar => {
        const isShowing = verticalNavbar.classList.contains('show-vertical-navbar');
        if (!isShowing) {
            verticalNavbar.classList.add('show-vertical-navbar');
            disableScroll();
        } else {
            verticalNavbar.classList.remove('show-vertical-navbar');
            enableScroll();
        }
    });
}

function closeVerticalNavbar(verticalNavbars) {
    "use strict";
    verticalNavbars.forEach(verticalNavbar => {
        if (verticalNavbar.classList.contains('show-vertical-navbar')) {
            verticalNavbar.classList.remove('show-vertical-navbar');
            enableScroll();
        }
    });
}

// Close nav outside click of the nav itself
function closeNavbarOnClickOutside(event, verticalNavbars, burgerMenus) {
    "use strict";
    verticalNavbars.forEach(verticalNavbar => {
        if (!verticalNavbar.contains(event.target) && ![...burgerMenus].some(menu => menu.contains(event.target))) {
            closeVerticalNavbar(verticalNavbars);
        }
    });
}

function debounce(func, wait) {
    let timeout;
    return function() {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, arguments), wait);
    };
}

const debouncedUpdateNavbarOnResize = debounce(function(verticalNavbars) {
    "use strict";
    if (window.innerWidth > 768) {
        closeVerticalNavbar(verticalNavbars);
    }
}, 150); 

// Enable href navigation to a button and page
function addNavigation(buttons, url) {
    "use strict";
    if (!buttons) return;

    buttons.forEach(button => {
        utils.addEventListenerCompat(button, 'click', function () {
            const packageMap = {
                'basic-package': 'BasicPackage',
                'standard-package': 'StandardPackage',
                'premium-package': 'PremiumPackage',
                'corporate-package': 'CorporatePackage'
            };

            const selectedPackage = packageMap[button.id] || 'BasicPackage';

            const isShopButton = button.classList.contains('shop-button');
            const finalUrl = isShopButton ? `${url}?package=${selectedPackage}` : url;

            window.location.href = finalUrl;
        });
    });
}

async function submitForm(form, validator) {
    const formData = new FormData(form);
    const url = form.getAttribute('action');
    const method = form.method.toUpperCase() || 'POST';
    
    try {
        const response = await fetch(url, {
            method: method,
            body: formData
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Parsed Response:', data); // Log the parsed JSON response

        if (!data) {
            throw new Error('No data received from the server');
        }

        if (data.errors && typeof data.errors === 'object') {
            Object.keys(data.errors).forEach(key => {
                const input_1 = form.querySelector(`[name=${key}]`);
                if (input_1) {
                    const formGroup = input_1.parentElement;
                    const errMessage = formGroup ? formGroup.querySelector('.error-message') : null;
                    if (formGroup && errMessage) {
                        validator.showError(errMessage, formGroup, data.errors[key]);
                    }
                }
            });
        } else if (data.success) {
            if (data.message === 'Verification code sent to your email.') {
                validator.Popup.show('Verification code sent to your email. Please enter it to proceed');
                validator.showVerificationForm();
                validator.startVerificationTimeout();
            } else if (data.message === 'complete') {
                window.location.href = 'thank-you.html';
            }
        }
    } catch (error) {
        console.error('Error!', error);
        alert('There was an error processing your request. Please try again.');
    }
}

function updateYearsWorked() {
    const currentYear = new Date().getFullYear();
    const yearsWorkedElements = document.querySelectorAll('.years-worked');

    if(yearsWorkedElements === null) return;

    yearsWorkedElements.forEach(function(element) {
        const startYear = parseInt(element.getAttribute('data-start-year'));

        // Calculate the number of years worked
        const yearsWorked = currentYear - startYear;

        // Update the text content to show the number of years worked
        element.textContent = `Years worked: ${yearsWorked} years`;
    });
}

// init page
function init() {
    "use strict";
    // Constants for header/nav
    const burgerMenus = document.querySelectorAll('.burger-menu');
    const verticalNavbars = document.querySelectorAll('.vertical-navbar');
    const categoryContainers = document.querySelectorAll('.package-category');
    // Constants for buttons
    const closeBtn = document.querySelector('.close-vertical-navbar');
    const contactButtons = document.querySelectorAll('.contact-button');
    const aboutSideButtons = document.querySelectorAll('.extra-button');
    const shopButtons = document.querySelectorAll('.shop-button');
    const connectButtons = document.querySelectorAll('.connect-button');
    // Carousel Constants
    const container = document.querySelector('.image-container');
    const images = document.querySelectorAll('.image-container img');
    // Form Constants
    const commentForms = document.querySelectorAll('.comment-form');
    const contactForm = document.querySelector('.contact-form');
    const verificationForm = document.querySelector('.verification-form');
    const productsForm = document.querySelector('.product-form');
    const formValidators = [];
    // Initialize the carousel
    initCarousel(container, images);
    updateYearsWorked();

    // Event listeners
    if (burgerMenus.length > 0) {
        burgerMenus.forEach(menu => {
            utils.addEventListenerCompat(menu, 'click', () => toggleVerticalNavbar(verticalNavbars));
        });
    }
    
    if (closeBtn) {
        utils.addEventListenerCompat(closeBtn, 'click', () => closeVerticalNavbar(verticalNavbars));
    }

    categoryContainers.forEach(container => {
        const toggleCategory = container.querySelector('.toggle-category');
        const dropdown = container.querySelector('.dropdown-content');
        const arrow = toggleCategory?.querySelector('.arrow');
    
        if (!toggleCategory || !dropdown || !arrow) return;
    
        utils.addEventListenerCompat(toggleCategory, 'click', () => {
            const isHidden = dropdown.classList.contains('dropdown-hidden');
            
            dropdown.classList.toggle('dropdown-hidden', !isHidden);
            dropdown.classList.toggle('dropdown-visible', isHidden);
            arrow.classList.toggle('flipped', isHidden);
        });
    });    

    // charts

    const ctx = document.getElementById('lineChartCanvas')?.getContext('2d'); // Use optional chaining
    if (ctx) {
        const data = {
            labels: ['Red', 'Blue', 'Yellow', 'Green'],
            datasets: [
                {
                    data: [300, 50, 100, 80],
                }
            ]
        };
        
        const options = {
            colors: ['red', 'blue', 'yellow', 'green']  // Define colors for each slice
        };
        
        new utils.Chart(ctx, 'pie', data, options);
    } else {
        console.error('Canvas context not found. Check the canvas element ID and ensure it exists.');
    }

    utils.addEventListenerCompat(window, 'resize', () => debouncedUpdateNavbarOnResize(verticalNavbars));
    utils.addEventListenerCompat(document, 'click', (event) => closeNavbarOnClickOutside(event, verticalNavbars, burgerMenus));

    // Event handlers for button clicks
    addNavigation(connectButtons, '/contact');
    addNavigation(contactButtons, '/contact');
    addNavigation(aboutSideButtons, '/about');
    addNavigation(shopButtons, '/contact');
    
    commentForms.forEach(form => {
        formValidators.push(new utils.FormValidator(form));
    });

    if(contactForm) {
        const contactValidator = new utils.FormValidator(contactForm);
        contactValidator.setOnSubmit((form) => {
            submitForm(form, contactValidator);
        });
    }

    if(verificationForm) {
        const verifyValidator = new utils.FormValidator(verificationForm);
        verifyValidator.setOnSubmit((form) => {
            submitForm(form, verifyValidator);
        });
    }

    if(productsForm) {
        const productValidator = new utils.FormValidator(productsForm);

        productValidator.setOnSubmit((form) => {
            product_manager.submitFormData(form);
        });
    }
}


window.onload = init;