import utils from './utils.js'
const popupElement = new utils.Popup();

/**
 * Function to handle button clocks and show the inner data of a product
 */
function setupProductPopup() {
    const detailsButtons = document.querySelectorAll('.details-button');
    detailsButtons.forEach(button => {
        button.addEventListener('click', () => {
            // Create or get existing popup instance
            const popup = new utils.Popup();

            const title = button.dataset.productTitle;
            const featureHeader = button.dataset.productFeatureHeader;
            const featureList = button.dataset.productFeatureList;
            const extraImages = button.dataset.productExtraImages.split(',');
            const description = button.dataset.productDescription;
            const price = button.dataset.productPrice;
            const downloadLink = button.dataset.productDownloadLink;

            // Clear previous content
            popup.extraContentElement.innerHTML = '';

            // Add content sections to the popup
            if (title) {
                popup.addSection('h2', title);
            }
            if (featureHeader && featureList) {
                popup.addSection('h3', featureHeader);
                popup.addSection('ul', featureList.split(',').map(item => `<li>${item}</li>`).join(''));
            }
            if (extraImages.length > 0) {
                // Carousel container
                const carouselHtml = `
                    <div class="shop-carousel">
                        <div class="shop-carousel-images">
                            ${extraImages.map(src => `<img loading="eager" class="popup-image" src="images/${src}" alt="Product Image">`).join('')}
                        </div>
                        <button class="shop-carousel-prev">&#10094;</button>
                        <button class="shop-carousel-next">&#10095;</button>
                    </div>`;
                popup.addSection('div', carouselHtml);
                
                // Add carousel functionality
                const carouselImages = popup.extraContentElement.querySelector('.shop-carousel-images');
                const images = carouselImages.querySelectorAll('img');
                let currentIndex = 0;

                function updateCarousel() {
                    const offset = -currentIndex * 100;
                    carouselImages.style.transform = `translateX(${offset}%)`;
                }

                const prevButton = popup.extraContentElement.querySelector('.shop-carousel-prev');
                const nextButton = popup.extraContentElement.querySelector('.shop-carousel-next');

                prevButton.addEventListener('click', () => {
                    currentIndex = (currentIndex > 0) ? currentIndex - 1 : images.length - 1;
                    updateCarousel();
                });

                nextButton.addEventListener('click', () => {
                    currentIndex = (currentIndex < images.length - 1) ? currentIndex + 1 : 0;
                    updateCarousel();
                });

                updateCarousel();
            }
            if (description) {
                popup.addSection('p', description);
            }
            if (price) {
                popup.addSection('p', `<strong>Price:</strong> ${price}`);
            }
            if (downloadLink) {
                popup.addSection('a', `<button>Download</button>`);
                const downloadButton = popup.extraContentElement.querySelector('a');
                if (downloadButton) {
                    downloadButton.href = downloadLink;
                    downloadButton.target = '_blank';
                }
            }

            // Show the popup with the constructed content
            popup.show("Product Details");
        });
    });
}

/**
 * Function to submit form data via AJAX
 * @param {HTMLFormElement} form - The form element to be submitted
 * @returns {Promise<void>}
 */
async function submitFormData(form) {
    console.log("SUBMITTING THE FORM NOW!");
    const formData = new FormData(form); // Use FormData directly

    try {
        // Send the data to the server
        const response = await fetch('php/product_manager.php', {
            method: 'POST',
            // Do not set Content-Type header manually
            body: formData, // FormData handles its own Content-Type
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();

        if (result.error) {
            console.error('Error:', result.error);
        } else {
            console.log('Success:', result.message);
            console.log('Submitted Data:', result.submitted_data);
            location.reload();
        }
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
}

/**
 * Add event listeners to edit and delete buttons
 */
function addTableEventListeners() {
    // Handle Edit Button Click
    document.querySelectorAll('.edit-btn').forEach(button => {
        button.addEventListener('click', (event) => {
            const productId = event.target.dataset.id;
            editProduct(productId);
        });
    });
    
    // Handle Delete Button Click
    document.querySelectorAll('.delete-btn').forEach(button => {
        button.addEventListener('click', (event) => {
            const productId = event.target.dataset.id;
            deleteProduct(productId);
        });
    });
}

/**
 * Function to delete a product
 * @param {string} productId - ID of the product to be deleted
 */
async function deleteProduct(productId) {
    try {
        const response = await fetch(`php/product_manager.php?action=delete_product&id=${productId}`, {
            method: 'POST',
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        if (result.error) {
            console.error('Error deleting product:', result.error);
        } else {
            console.log('Product deleted successfully');
            fetchAndDisplayProducts(); // Refresh product list after deletion
        }
    } catch (error) {
        console.error('There was a problem with deleting the product:', error);
    }
}

/**
 * Function to edit a product (this can be expanded)
 * @param {string} productId - ID of the product to be edited
 */
function editProduct(productId) {
    console.log('Editing product:', productId);
    // Add logic here to open an edit form, etc.
}

// Call fetchAndDisplayProducts on page load
document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('#products-table')) {
        addTableEventListeners();
    }
    if (document.querySelector('.shop-middle-content-section')) {
        setupProductPopup();
    }
});

export default {
    submitFormData
};